<template>
	<div class="login">
		<div><input v-model="account" placeholder="账号" class="account" type="text"></div>
		<div><input v-model="password" placeholder="密码" class="password" type="text"></div>
		<div><input v-model="name" placeholder="昵称" class="mail" type="text"></div>
		<div><input v-model="mail" placeholder="邮箱" class="mail" type="text"></div>
		<div>
			<input v-model="code" placeholder="验证码" class="code" type="text">
			<button @click="getCode" class="getcode">获取验证码</button>
		</div>
		<div><button @click="register" type="button">确认注册</button></div>
		<div><span @click="back">返回登录</span></div>
	</div>
</template>

<script>
	import {register} from "@/network/api";

	export default {
		name: "Register",
		data() {
			return {
				account: "",
				password: "",
				name: "",
				mail: "",
				code: ""
			}
		},
		methods: {
			register() {
				if (this.account.length < 5 || this.account.length > 20) {
					alert("账号位数不能小于5位或者大于20位")
				} else if (this.password === "") {
					alert("密码不能为空")
				} else if (this.mail === "") {
					alert("邮箱不能为空")
				} else if (this.code !== "48488") {
					alert("验证码错误")
				} else {
					register(this.account, this.password, this.name, this.mail).then(
						res => {
							if (res.code === 200) {
								alert("注册成功")
							} else {
								alert(res.msg)
							}
						}
					)
				}
			},
			back() {
				this.$router.replace("/user/login")
			},
			getCode() {
				console.log(this.mail);
			}
		}
	}
</script>

<style scoped lang="less">
	.login {
		margin: 2rem 1rem 0;
		>div {
			margin-bottom: 2rem;
			.account {
				background-image: url(../assets/img/account.png);
			}
			.password {
				background-image: url(../assets/img/password.png);
			}
			.mail {
				background-image: url(../assets/img/mail.png);
			}
			.code {
				background-image: url(../assets/img/auth.png);
				width: 70%;
			}
			.getcode {
				width: 30%;
				height: 1.6rem;
				font-size: .7rem;
			}
			>input {
				background-repeat: no-repeat;
				background-size: 1rem;
				background-position: .5rem;
				height: 1.6rem;
				width: 100%;
				border-bottom: 1px solid #666;
				font-size: 1rem;
				text-indent: 2rem;
			}
			>button {
				width: 100%;
				height: 2rem;
				background-color: #666;
				border-radius: 1rem;
				font-size: 1rem;
				color: #fff;
			}
			>span {
				font-size: .8rem;
				color: #666;
			}
		}
	}
</style>
